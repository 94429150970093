<template>
  <div class="function_permission">
    <el-select
      v-if="$route.params.type === 'user'"
      v-model="roleId"
      @change="handleRoleChange"
      placeholder="从角色模板中添加"
      style="margin-right: 10px"
    >
      <el-option
        v-for="role in roleList"
        :label="role.name"
        :value="role.id"
        :key="role.id"
      />
    </el-select>
    <el-tag type="warning" v-if="$route.params.type === 'user'">
      可根据所选的角色模板，初始化功能权限
    </el-tag>
    <h3>功能列表</h3>
    <el-tree
      ref="tree"
      :data="menuList"
      :props="defaultProps"
      show-checkbox
      :check-strictly="true"
      :default-checked-keys="selectedKeys"
      default-expand-all
      node-key="id"
    >
      <span slot-scope="{ node, data }" style="font-size: 14px">
        {{ node.label }}
        {{ data.visible === MENU_VISIBLE.HIDE ? '【隐藏菜单】' : '' }}
        {{ data.type === MENU_TYPE.BUTTON ? '（功能按钮）' : '' }}
      </span>
    </el-tree>
    <div class="bottom_buttons">
      <el-button type="primary" @click="handleReset">重置</el-button>
      <div>
        <el-button @click="handleBack">取消</el-button>
        <el-button type="primary" @click="handleSubmit"> 提交 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { userApi, roleApi, menuApi } from '@/api';
import { uniq } from 'lodash-es';
import { MENU_VISIBLE, MENU_TYPE } from '@/constants/user-status';
export default {
  data() {
    return {
      MENU_VISIBLE,
      MENU_TYPE,
      roleId: '',
      roleMenuMap: {},
      menuList: [],
      roleList: [],
      selectedKeys: [],
      defaultProps: {
        label: 'name',
        children: 'children',
      },
    };
  },
  created() {
    if (this.$route.params.type === 'user') {
      roleApi.getAllRoles({ pageSize: 1000 }).then(res => {
        if (res.success) {
          this.roleList = res.data.records;
        }
      });
    }
    this.getApi(this.$route.params.id).then(res => {
      this.selectedKeys = res.data;
    });
    this.fetchMenuList();
  },
  computed: {
    getApi() {
      return this.$route.params.type === 'role'
        ? roleApi.getRoleMenuListByRoleId
        : userApi.getUserMenuListByUserId;
    },
    editApi() {
      return this.$route.params.type === 'role'
        ? roleApi.editRoleMenu
        : userApi.editUserMenu;
    },
  },
  methods: {
    fetchMenuList() {
      menuApi.getMenuList().then(res => {
        if (res.success) {
          this.menuList = res.data;
        }
      });
    },
    async handleRoleChange(roleId) {
      if (!roleId) return;
      if (!(roleId in this.roleMenuMap)) {
        const res = await roleApi.getRoleMenuListByRoleId(roleId);
        if (res.success) {
          this.$set(this.roleMenuMap, roleId, res.data);
        }
      }
      this.selectedKeys = uniq(
        this.selectedKeys.concat(this.roleMenuMap[roleId]),
      );
    },
    handleSubmit() {
      const checkedKeys = this.$refs['tree'].getCheckedKeys();
      this.editApi({
        userId: this.$route.params.id,
        roleId: this.$route.params.id,
        leafMenuIds: checkedKeys,
      }).then(res => {
        if (res.success) {
          this.$message.success('功能权限设置成功');
          this.handleBack();
        }
      });
    },
    handleBack() {
      this.$router.back();
    },
    handleReset() {
      this.selectedKeys = [];
      this.roleId = '';
      this.$refs['tree'].setCheckedKeys([]);
    },
  },
};
</script>

<style lang="less" scoped>
.function_permission {
  h3 {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  .bottom_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
</style>
