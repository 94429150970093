<template>
  <el-form label-width="80px" :model="form" ref="form">
    <el-form-item label="供货商:">
      <el-radio-group v-model="form.supplierDataScope" @change="handleSupplierChange" :disabled="userType != '0'">
        <el-radio :label="PERMISSION_TYPE.NONE">无访问权限</el-radio>
        <el-radio :label="PERMISSION_TYPE.ALL">全部供货商</el-radio>
        <el-radio :label="PERMISSION_TYPE.SELECT">指定供货商</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="form.supplierDataScope === PERMISSION_TYPE.SELECT" prop="supplierIds"
      :rules="[{ required: true, message: '请选择供货商' }]">
      <el-select style="width: 40%" multiple v-model="form.supplierIds" placeholder="请选择指定供货商" :disabled="userType != '0'">
        <el-option v-for="item in supplierList" :label="item.companyName" :value="item.id" :key="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="客户:">
      <el-radio-group v-model="form.customerDataScope" @change="handleCustomerChange" :disabled="userType != '0'">
        <el-radio :label="PERMISSION_TYPE.NONE">无访问权限</el-radio>
        <el-radio :label="PERMISSION_TYPE.ALL">全部客户</el-radio>
        <el-radio :label="PERMISSION_TYPE.SELECT">指定客户</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="form.customerDataScope === PERMISSION_TYPE.SELECT" prop="customerIds"
      :rules="[{ required: true, message: '请选择客户' }]">
      <el-select multiple style="width: 40%" v-model="form.customerIds" placeholder="请选择指定客户" :disabled="userType != '0'">
        <el-option v-for="item in customerList" :label="item.customerName" :value="item.id" :key="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item v-if="userType == '0'">
      <div class="form_buttons">
        <el-button type="primary" @click="handleReset">重置</el-button>
        <div>
          <el-button @click="handleCancel">取消</el-button>
          <el-button type="primary" :loading="loading" @click="handleSubmit">提交</el-button>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { customerApi, roleApi, supplierApi, userApi } from '@/api';
import { PERMISSION_TYPE } from '@/constants/user-status';
import { assign } from '@/utils/tools';
const initialForm = {
  customerDataScope: PERMISSION_TYPE.NONE,
  supplierDataScope: PERMISSION_TYPE.NONE,
  supplierIds: [],
  customerIds: [],
};
export default {
  data() {
    return {
      loading: false,
      PERMISSION_TYPE,
      supplierList: [],
      customerList: [],
      form: initialForm,
      userType: '',
    };
  },
  created() {
    roleApi.getRoleInfo(this.$route.params.id).then(res => {
      this.userType = res.data.userType;
    });

    supplierApi.getSupplierList({ pageSize: 1000, onState:'1' }).then(res => {
      if (res.success) {
        this.supplierList = res.data.records;
      }
    });
    customerApi.getPartnerList({ pageSize: 1000, customerStatus: 'NORMAL' }).then(res => {
      if (res.success) {
        this.customerList = res.data.records;
      }
    });

    // 查询数据权限
    this.getApi(this.$route.params.id).then(res => {
      if (res.success) {
        assign(this.form, res.data);
      }
    });
  },
  computed: {
    // 获取数据权限api
    getApi() {
      return this.$route.params.type === 'role'
        ? roleApi.getRoleDataPermissionListByRoleId
        : userApi.getUserDataPermissionListByUserId;
    },
    // 修改数据权限api
    editApi() {
      return this.$route.params.type === 'role'
        ? roleApi.editRoleDataPermission
        : userApi.editUserDataPermission;
    },
  },
  methods: {
    handleReset() {
      this.$refs['form'].resetFields();
      this.form = initialForm;
    },
    handleCancel() {
      this.$router.back();
    },
    // 供应商变更
    handleSupplierChange(value) {
      this.form.supplierIds = [];
      if (value === PERMISSION_TYPE.SELECT) {
        this.$refs['form'].clearValidate();
        return;
      }
    },
    // 客户变更
    handleCustomerChange(value) {
      this.form.customerIds = [];
      if (value === PERMISSION_TYPE.SELECT) {
        this.$refs['form'].clearValidate();
        return;
      }
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.editApi({
            ...this.form,
            userId: this.$route.params.id,
            roleId: this.$route.params.id,
          }).then(res => {
            this.loading = false;
            if (res.success) {
              this.$message.success('数据权限设置成功');
              this.handleCancel();
            }
          }).finally(()=>{
            this.loading = false;
          });
        }
      });
    },
  },
};
</script>

<style lang="css">
.form_buttons {
  display: flex;
  justify-content: space-between;
}
</style>
