<template>
  <main-content>
    <el-tabs v-model="activeName">
      <el-tab-pane label="功能权限" name="function">
        <function-permission />
      </el-tab-pane>
      <el-tab-pane v-if="isSysUser" lazy label="数据权限" name="data">
        <data-permission />
      </el-tab-pane>
    </el-tabs>
  </main-content>
</template>

<script>
import MainContent from '@/components/MainContent.vue';
import FunctionPermission from './function-permission.vue';
import DataPermission from './data-permission.vue';
import { USER_TYPE } from '@/constants/user-status';
export default {
  components: { MainContent, FunctionPermission, DataPermission },
  data() {
    return {
      activeName: 'function',
    };
  },
  computed: {
    isSysUser() {
      return (this.$store.state.userInfo.userType === USER_TYPE.SYS_MANAGER) && this.$route.params.type != 'role';
    },
  }
};
</script>

<style></style>
